<template>
  <div>
    <aboutsPageTopMb />
    <div class="content-box">
      <!-- <el-image class="app_all" :src="contentList" width="100%" lazy></el-image> -->
      <el-image class="app_all" :src="contentList[language]" width="100%" alt="" @load="imgOnload">
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <el-image class="app_Footer" :src="appFooter" width="100%" lazy @load="imgOnload"></el-image>
      <div class="" v-if="language !=='en' && showBtn">
        <!-- <a class="app_Google" href=" https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
          <el-image :src="appGoogle" width="100%" lazy></el-image>
        </a> -->
        <a class="app_Store" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
          <el-image :src="appStore" width="100%" lazy></el-image>
        </a>
      </div>
      <div class="" v-if="language =='en' && showBtn">
        <a class="app_Google2" href=" https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
          <el-image :src="appGoogle" width="100%" lazy></el-image>
        </a>
        <a class="app_Store2" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
          <el-image :src="appStore" width="100%" lazy></el-image>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang"
import { aboutBannerList, homeLanList } from '@/utils/public_data'
import aboutsPageTopMb from '@/components/aboutsPage-top-mb'
export default {
  data() {
    return {
      detailForm: false,
      logo: require("@/assets/images/customer/MB-home.png"),
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      appGoogle: require("@/assets/images/aboutus/app_google.png"),
      appStore: require("@/assets/images/aboutus/app_store.png"),
      appFooter: require("@/assets/images/aboutus/app_footer.png"),
      showPopover: false,
      showBtn: false,
      screenWidth: document.body.clientWidth,
      actions: [
        { text: '日本語', lan: 'ja' },
        { text: '简体中文', lan: 'zh' },
        { text: '繁體中文', lan: 'zh_tw' },
        { text: 'English', lan: 'en' },
        { text: '한국어', lan: 'ko' }
      ],
      lanList: homeLanList(),
      contentList: aboutBannerList(),
    }
  },
  components: {
    aboutsPageTopMb
  },

  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    onSelect(action) {
      console.log(action)
      this.$i18n.locale = action.lan;
      localStorage.setItem("locale", action.lan);
      reloadMessage();
      this.$router.go(0);
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  }
}
</script>
<style lang='scss' scoped>
.content-box {
  position: relative;
  margin-top: 10px;
}
.app_all {
  position: relative;
}
.app_Google {
  position: absolute;
  bottom: 91px;
  right: 153px;
  width: 43px;
}
.app_Store {
  position: absolute;
  bottom: 91px;
  right: 105px;
  width: 43px;
}
.app_Google2 {
  position: absolute;
  bottom: 65px;
  right: 153px;
  width: 43px;
}
.app_Store2 {
  position: absolute;
  bottom: 65px;
  right: 105px;
  width: 43px;
}
.app_Footer {
  position: absolute;
  left: 0;
  bottom: 0;
  // height: 28px;
  width: 100%;
}
@media screen and (min-width: 375px) and(max-width: 414px) {
  .app_Google {
    position: absolute;
    bottom: 8.1%;
    right: 35.7%;
    width: 42px;
  }
  .app_Store {
    position: absolute;
    bottom: 8.1%;
    right: 24.7%;
    width: 42px;
  }
  .app_Google2 {
    position: absolute;
    bottom: 5.3%;
    right: 34.7%;
    width: 42px;
  }
  .app_Store2 {
    position: absolute;
    bottom: 5.3%;
    right: 22.7%;
    width: 42px;
  }
}
@media screen and (min-width: 360px) and(max-width: 374px) {
  .app_Google {
    position: absolute;
    bottom: 75px;
    right: 130px;
    width: 36px;
  }
  .app_Store {
    position: absolute;
    bottom: 75px;
    right: 89px;
    width: 36px;
  }
  .app_Google2 {
    position: absolute;
    bottom: 52px;
    right: 130px;
    width: 36px;
  }
  .app_Store2 {
    position: absolute;
    bottom: 52px;
    right: 89px;
    width: 36px;
  }
}
@media screen and (min-width: 320px) and(max-width: 359px) {
  .app_Google {
    position: absolute;
    bottom: 78px;
    right: 134px;
    width: 38px;
  }
  .app_Store {
    position: absolute;
    bottom: 78px;
    right: 93px;
    width: 38px;
  }
  .app_Google2 {
    position: absolute;
    bottom: 78px;
    right: 134px;
    width: 38px;
  }
  .app_Store2 {
    position: absolute;
    bottom: 78px;
    right: 93px;
    width: 38px;
  }
}
@media screen and(max-width: 320px) {
  .app_Google {
    position: absolute;
    bottom: 65px;
    right: 115px;
    width: 33px;
  }
  .app_Store {
    position: absolute;
    bottom: 65px;
    right: 79px;
    width: 33px;
  }
  .app_Google2 {
    position: absolute;
    bottom: 45px;
    right: 115px;
    width: 33px;
  }
  .app_Store2 {
    position: absolute;
    bottom: 45px;
    right: 79px;
    width: 33px;
  }
}
</style>
